<template>
    <div 
        class="call" 
        @mousedown.stop="down" 
        @touchstart.stop="down"
        @mousemove.stop="move"
        @touchmove.stop="move" 
        @mouseup.stop="end" 
        @touchend.stop="end" 
        :style="{top:positionY+'px', left:positionX+'px'}">
        <a :href="'tel:' + tel">
            <img class="call_icon" src="@/assets/rescueImgs/call_icon.png" alt="">
        </a>
    </div>
</template>

<script>
let screenWidth = window.screen.width;
let screenHeight = window.screen.height;
export default {
    data(){
        return {
            flags: false,
            positionX: screenWidth - 66,
            positionY: 80,
            tel: ''
        }
    },
    mounted(){
        this.tel = process.env.VUE_APP_BASE_TEL;
    },
    methods: {
        down() {
            this.flags = true;
            this.fixedBody();
        },
        move(event) {
            if (this.flags) {
                var touch;
                if (event.touches) {
                    touch = event.touches[0];
                } else {
                    touch = event;
                }
                // 定位滑块的位置
                this.positionX = touch.clientX;
                this.positionY = touch.clientY;
                // 限制滑块超出页面
                // console.log('屏幕大小', screenWidth, screenHeight )
                if (this.positionX < 0) {
                    this.positionX = 0
                } else if (this.positionX > screenWidth - touch.target.clientWidth) {
                    this.positionX = screenWidth - touch.target.clientWidth
                }
                if (this.positionY < 0) {
                    this.positionY = 0
                } else if (this.positionY > screenHeight - touch.target.clientHeight) {
                    this.positionY = screenHeight - touch.target.clientHeight
                }
                //阻止页面的滑动默认事件
                document.addEventListener("touchmove", function () {
                    event.preventDefault();
                }, false);
            }
        },
        //鼠标释放时候的函数
        end() {
            this.flags = false;
            this.looseBody();
        },
        fixedBody () {
            let scrollTop = document.body.scrollTop || document.documentElement.scrollTop
            document.body.style.cssText += 'position:fixed;width:100%;top:-' + scrollTop + 'px;'
        },
        looseBody () {
            let body = document.body
            body.style.position = ''
            let top = body.style.top
            document.body.scrollTop = document.documentElement.scrollTop = -parseInt(top)
            body.style.top = ''
        }
    }
}
</script>

<style lang='scss' scoped>
    .call{
        position: fixed;
        right: 0;
        top: 60px;
        z-index: 99;
        .call_icon{
            width: 66px;
            height: 66px;
        }
    }
</style>
